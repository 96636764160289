import Vue from 'vue'
import {createWebHistory, createRouter} from 'vue-router'
import Home from '../views/Home.vue'
import Banking from '../components/Banking'
import DirectDebit from '../components/DirectDebit'
import MerchantService from '../components/MerchantService'
import IVRCallMasking from '@/components/IVRCallMasking.vue'
import PaymentGateway from '@/components/PaymentGateway.vue'
import InnovationFS from '@/components/InnovationFS.vue' 
import BankTransactionReport from '@/components/BankTransactionReport'
import MainComponent from '@/components/pages/main'
import SuppliersView from '@/components/pages/suppliers/suppliers_view'
import TransactionTypesComponent from '@/components/pages/transaction_types/transaction_types_component'
import DashboardComponent from '@/components/pages/dashboard/dashboard'
import SuppliersComponent from '@/components/pages/suppliers/suppliers_component'
import UsersComponent from '@/components/pages/users/users_component'
import CardTransactionsMainComponent from '@/components/pages/card_transactions/card_transactions_main_component'
import CardImportsList from '@/components/pages/card_imports/CardImportsList'
import AllCardImportsList from '@/components/pages/card_imports/admin_access/AllCardImportsList'

import RolesComponent from '@/components/pages/roles/RolesListComponent.vue'
import PermissionsComponent from '@/components/pages/settings/PermissionsComponent.vue'
import ProductsMainComponent from '@/components/pages/products/ProductsMainComponent'
import ProductsComponent from '@/components/pages/products/products_component'

import ProductRfqsComponent from '@/components/pages/product_rfqs/ProductRfqsComponent'
import FirebaseNotificationComponent from '@/components/pages/firebase_notification/FirebaseNotificationList'
import CategoriesComponent from '@/components/pages/categories/CategoriesList'
import CardTypeRatesComponent from '@/components/pages/card_type_rates/CardTypeRatesList'
import CardTypeTermsComponent from '@/components/pages/card_type_terms/CardTypeTermsList'
import CompaniesComponent from '@/components/pages/companies/companies_component'
import SpecificationsComponent from '@/components/pages/specifications/SpecificationsComponent'

import CompanyFacaList from '@/components/pages/CompanyFaca/CompanyFacaList'

import SocialValuesComponent from '@/components/pages/social_value/SocialValuesComponent'
import SupplierSocialValuesComponent from '@/components/pages/supplier_social_values/SupplierSocialValuesComponent'
import MerchantTransactionsReportListComponent from '@/components/pages/merchant_transactions_report/MerchantTransactionsReportListComponent'

import GlobalTransactionTypesComponent from '@/components/pages/global_transaction_types/global_transaction_types_component'
import HelpComponent from '@/components/pages/help/help_component'
import HelpRequestComponent from '@/components/pages/help/help_requests'
import Login from '@/components/login/Login.vue'
import SignUpComponent from '@/components/signup/SignUpComponent.vue'

import Logout from '@/components/logout/Logout.vue'
import Profile from '@/components/pages/user/profile'
import AccountSetting from '@/components/pages/user/UserSetting'

import ApplicationThemeConfigComponent from '@/components/pages/application_themes/ApplicationThemeConfig'
import HistoryLogsListcomponent from '@/components/pages/application_histories/HistoryLogsList.vue'
import ProjectSettingsViewComponent from '@/components/pages/procurement_projects/ProjectSettingsView.vue'
import SQuestionComponent from '@/components/pages/sq_questions/SQuestionForm.vue'
import SubmissionList from '@/components/pages/sq_questions/SubmissionList.vue'
import PasswordReset from '@/components/pages/user/password_reset'
import SavedSearchesList from '@/components/pages/search_solutions/SavedSearchesList.vue'
import SearchSolutionsMain from '@/components/pages/search_solutions/SearchSolutionsMain.vue'
import SearchSolutionComponent from '@/components/pages/search_solutions/SearchSolutionsComponent.vue'
// import SelectedSolutionComponent from '@/components/pages/search_solutions/SelectedSolutionComponent.vue'
import SelectedProductFullViewComponent from '@/components/pages/search_solutions/SelectedProductFullViewComponent.vue'
import SupplierSettingComponent from '@/components/pages/supplier_setting/SupplierSettingComponent.vue'
import CompanySettingsCompany from '@/components/pages/company_settings/CompanySettingsComponent.vue'
import SupplierSpecificationsComponent from '@/components/pages/supplier_specifications/SupplierSpecificationsComponent.vue'

import MainComparisonComponent from '@/components/pages/comparison/MainComparisonComponent.vue'

import MerchantServiceReport from '@/components/pages/cost_comparison/merchant_services/MerchantServiceReportComponent.vue'
import MerchantTerminologies from '@/components/pages/merchants/MerchantTerminologiesComponent.vue'
import MerchantBffRatesComponent from '@/components/pages/merchants/MerchantBffRatesComponent.vue'
import ProductSearchResultsComponent from '@/components/pages/product_search_results/product_search_results_component.vue'
import BuyerSearchQueriesComponent from '@/components/pages/product_search_queries/ProductSearchQueriesComponent.vue'
import SelectedBuyerSearchQueryComponent from '@/components/pages/product_search_queries/SelectedBuyerSearchQueryComponent.vue'
import BuyerSearchQueriesMainComponent from '@/components/pages/product_search_queries/BuyerSearchQueriesMainComponent.vue'

import DetailedCostComparisonFromProductSearchResults from '@/components/pages/product_search_results/DetailedCostComparisonFromProductSearchResults.vue'

import SupplierViewComponent from '@/components/pages/supplier_view/SupplierViewComponent'
import AuthorizationFeesComponent from '@/components/pages/authorization_fees/AuthorizationFeesComponent'
// import SuppliersHubDashboard from '@/components/pages/SuppliersHub/SuppliersHubDashboard'
import SupplierViewPinPage from '@/components/pages/pin_notice/SupplierViewPinPage'
import PinNoticesList from '@/components/pages/pin_notice/PinNoticesList'
import ProcurementProjectsComponent from '@/components/pages/procurement_projects/ProcurementProjectsList'
import MeetingsHomeComponent from '@/components/pages/jaas_meetings/MeetingsHomeComponent'
import ExternalUserLandingPage from '@/components/pages/jaas_meetings/ExternalUserLandingPage'
import MyPipelineComponent from '@/components/pages/procurement_projects/my_pipeline/MyPipelineList'
import GlobalEmailTemplateComponent from '@/components/pages/customisable_email/GlobalEmailTemplateList'
import ForumComponent from '@/components/pages/forum/ForumMain'
import GroupComponent from '@/components/pages/group_management/GroupList'

// Lazy loading routes
const SelectedProductComponent = () =>
  import('@/components/pages/products/SelectedProductComponent')
const MonthlyReportingCategory = () => 
  import('@/components/pages/dashboard/monthly_reporting/MonthlyReportingCategory')
const MonthlyReporting = () => 
  import('@/components/pages/dashboard/monthly_reporting/MonthlyReporting')
const MRBanking = () =>
  import('@/components/pages/dashboard/monthly_reporting/MRBanking')
const MRMerchantServices = () =>
  import('@/components/pages/dashboard/monthly_reporting/MRMerchantServices')
const FilesManagementComponent = () =>
  import('@/components/pages/files/FilesManagementComponent')
const BankReports = () => import('@/components/pages/bank_reports/BankReports')
const BrTransaction = () =>
  import('@/components/pages/bank_reports/transactions/BrTransaction')
const BrTransactionList = () =>
  import('@/components/pages/bank_reports/transactions/BrTransactionList')
const BrTransactionReportList = () =>
  import('@/components/pages/bank_reports/transactions/BrTransactionReportList')
const BrTransactionHistory = () =>
  import('@/components/pages/bank_reports/transactions/BrTransactionHistory')
const BrTariffRateList = () =>
  import('@/components/pages/bank_reports/tariff_rates/BrTariffRateList')
const BrFreebankingList = () =>
  import('@/components/pages/bank_reports/freebankings/BrFreebankingList')
const BrImportList = () =>
  import('@/components/pages/bank_reports/imports/BrImportList')
  const AllBrImportList = () =>
  import('@/components/pages/bank_reports/imports/admin_access/AllBrImportList')
const BrInvoiceList = () =>
  import('@/components/pages/bank_reports/invoices/BrInvoiceList')
const CtInvoiceList = () =>
  import('@/components/pages/card_transactions/invoices/CtInvoiceList')
const BankingSupplierProspectList = () =>
  import(
    '@/components/pages/banking_tool/supplier_prospects/BankingSupplierProspectList'
  )
const BankingTariffRateList = () =>
  import('@/components/pages/banking_tool/tariff_rates/BankingTariffRateList')
const BankingCostComparison = () =>
  import(
    '@/components/pages/banking_tool/cost_comparison/BankingCostComparison'
  )
const BankingReportList = () =>
  import('@/components/pages/banking_tool/reports/BankingReportList')
const BRExportsListComponent = () =>
  import('@/components/pages/bank_report_exports/BRExportsListComponent')
const CompanyInvoiceList = () =>
  import('@/components/pages/company_invoice/CompanyInvoiceList')
const FinanceCalendar = () =>
  import('@/components/pages/finance_module/calendar/FinanceCalendar')

const ResetPassword = () =>
  import('@/components/reset_password/ResetPassword.vue')

const CouncilsList = () =>
  import('@/components/pages/councils/CouncilsList.vue')

const frameworkNameList = () =>
  import('@/components/pages/framework_names/frameworkNameList.vue')

const SupplierHubMain = () =>
  import('@/components/pages/SuppliersHub/SupplierHubMain.vue')
const SupplierHubSignup = () =>
  import('@/components/pages/SuppliersHub/SupplierHubSignup.vue')
const BuyerSignUp = () => import('@/components/pages/buyer/buyerSignUp.vue')
const SupplierHubManageList = () =>
  import('@/components/pages/SuppliersHub/SupplierHubFilesList.vue')

const ManageSupplierHubFiles = () =>
  import('@/components/pages/SuppliersHub/ManageSupplierHubFiles.vue')
const ManageSupplierHubQuestions = () =>
  import('@/components/pages/SuppliersHub/ManageSupplierHubQuestions.vue')

const QuestionsAndAnswersMain = () =>
  import('@/components/pages/questions_and_answers/QuestionsAndAnswersMain.vue')

const SuppliersHubDashboard = () =>
  import('@/components/pages/SuppliersHub/SuppliersHubDashboard')
const PageNotFound = () =>
  import('@/components/common/PageNotFoundComponent.vue')

const RestrictAfterLoggedInComponent = () =>
  import('@/components/common/RestrictAfterLoggedInComponent.vue')

const ForgotPassword = () => import('@/components/login/ForgotPassword.vue')

const SupplierHubNotifications = () =>
  import(
    '@/components/pages/supplier_hub_notifications/SupplierHubNotificationsList.vue'
  )

const SupplierPendingCallOffsComponent = () =>
  import(
    '@/components/pages/product_search_queries/SupplierPendingCallOffsComponent.vue'
  )
const ProductSearchMessageList = () =>
  import('@/components/pages/product_search_messages/ProductSearchMessageList')

const BuyerCallOffDocuments = () =>
  import('@/components/pages/product_search_queries/BuyerCallOffDocuments')

const SettingsList = () => import('@/components/pages/settings/SettingsList')

const ProductsReviewList = () => import('@/components/pages/settings/Products/ProductsReviewList')

const CallOffsApprovalList = () => import('@/components/pages/settings/CallOffs/CallOffsApprovalList')

const FinanceMain = () =>
  import('@/components/pages/finance_module/FinanceMain')

const ChartOfAccounts = () =>
  import('@/components/pages/finance_module/account_charts/ChartOfAccountsList')

const FinanceTypesList = () =>
  import('@/components/pages/finance_module/account_charts/FinanceTypesList')

const FinanceAreasList = () =>
  import('@/components/pages/finance_module/account_charts/FinanceAreasList')

const AgingReport = () =>
  import('@/components/pages/finance_module/aging_reports/AgingReport')

const SupportTicketHome = () =>
  import('@/components/pages/support_tickets/SupportTicketHome')

const AuditsList = () => import('@/components/pages/settings/Audits/AuditsList')

const IttFrameworksList = () =>
  import('@/components/pages/itt_frameworks/IttFrameworksList')

const CompanyBcp = () =>
  import('@/components/pages/company_bcp/CompanyBcp')

const BcpMain = () =>
  import('@/components/pages/bcp/BcpMain')

const BcpRedirect = () =>
  import('@/components/login/BcpRedirect')


const BcpProductsList = () =>
  import('@/components/pages/bcp/bcp_products/BcpProductsList')


const BcpSuppliersList = () =>
  import('@/components/pages/bcp/bcp_suppliers/BcpSuppliersList')

const BcpList = () =>
  import('@/components/pages/settings/BCP/BCPList')

const ManageHelpResourcesList = () =>
  import('@/components/pages/help_resources/ManageHelpResourcesList')


const UserHelpResourcesList = () =>
  import('@/components/pages/help_resources/UserHelpResourcesList')


const LookerStudiosList = () =>
  import('@/components/pages/looker_studios/LookerStudiosList')

const ClientLookerStudiosList = () =>
  import('@/components/pages/looker_studios/ClientLookerStudiosList')


import store from '@/store/index'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { public: false }
  },
  {
    path: '/reset_password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { public: true }
  },
  {
    path: '/buyer-signup',
    name: 'BuyerSignUp',
    component: BuyerSignUp,
    meta: { public: true },
    beforeEnter: async (to, from, next) => {
      const users = store.state.savedUser
      if(users){
        return next({ path: '/page_unavailable' });
      }
      next();
    },
  },
  {
    path: '/supplier-hub/signup',
    name: 'SupplierHubSignUp',
    component: SupplierHubSignup,
    meta: { public: true },
    beforeEnter: async (to, from, next) => {
      const users = store.state.savedUser
      if(users){
        return next({ path: '/page_unavailable' });
      }
      next();
    },
  },
  {
    path: '/supplier-hub/dashboard',
    name: 'SuppliersHubDashboard',
    component: SuppliersHubDashboard,
    meta: { public: false }
  },
  {
    path: '/supplier/:name',
    name: 'SupplierView',
    component: SupplierViewComponent,
    meta: { public: false }
  },
  {
    path: '/supplier',
    redirect: '/supplier/not_found',
    meta: { public: false }
  },
  {
    path: '/search-solution',
    name: 'SearchSolution',
    component: SearchSolutionsMain,
    redirect: '',
    meta: { public: false },
    children: [
      {
        path: '',
        name: 'SearchSolutionsList',
        component: SearchSolutionComponent,
        meta: { public: false }
      },
      {
        path: ':id',
        component: SelectedProductFullViewComponent,
        name: 'SelectedSolution',
        props: true,
        meta: { public: false }
      }
    ]
  },
  {
    path: '/comparison-tool',
    name: 'ComparisonTool',
    component: MainComparisonComponent,
    meta: { public: false }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { public: true }
  },
  {
    path: '/bcp-redirect',
    name: 'BcpRedirect',
    component: BcpRedirect,
    meta: { public: true }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: { public: true }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: { public: false }
  },
  {
    path: '/forum/:type/:id?',
    name: 'Forum',
    component: ForumComponent,
    meta: { public: false }
  },
  {
    path: '/main',
    name: 'Main',
    component: MainComponent,
    meta: { public: false },
    children: [
      {
        path: 'looker-studios',
        component: ClientLookerStudiosList,
        name: 'ClientLookerStudiosList',
        meta: { public: false }
      },
      {
        path: 'group-management',
        name: 'Group Management',
        component: GroupComponent,
        props: true,
        meta: { public: false }
      },
      {
        path: 'global-email-templates',
        name: 'Global Email Templates',
        component: GlobalEmailTemplateComponent,
        props: true,
        meta: { public: false }
      },
      {
        path: 'join-meet-a-buyer/:link',
        name: 'Join in Meet a Buyer Event',
        component: ExternalUserLandingPage,
        props: true,
        meta: { public: false }
      },
      {
        path: 'application_theme/:type',
        component: ApplicationThemeConfigComponent,
        name: 'Application Theme'
      },
      {
        path: 'bcp',
        name: 'BcpMain',
        component: BcpMain,
        meta: { public: false },
        redirect: to => {
          return { name: 'CompanyBcp' }
        },
        children: [
          {
            path: 'products',
            component: BcpProductsList,
            name: 'BcpProductsList',
            meta: { public: false }
          },
          {
            path: 'suppliers',
            component: BcpSuppliersList,
            name: 'BcpSuppliersList',
            meta: { public: false }
          },
           {
              path: 'overview',
              component: CompanyBcp,
              name: 'CompanyBcp',
              meta: { public: false }
            },
        ]
      },
      {
        name: 'MainHome',
        path: '',
        redirect: to => {
          return { name: 'dashboard' }
        },
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        name: 'dashboard',
        meta: { public: false }
      },
      {
        path: 'manage-help-resources',
        component: ManageHelpResourcesList,
        name: 'ManageHelpResourcesList',
        meta: { public: false }
      },
      {
        path: 'help-resources',
        component: UserHelpResourcesList,
        name: 'UserHelpResourcesList',
        meta: { public: false }
      },
      {
        path: 'saved_searches',
        component: SavedSearchesList,
        name: 'saved_searches',
        meta: { public: false }
      },
      {
        path: 'audits',
        component: AuditsList,
        name: 'AuditsList',
        meta: { public: false }
      },
       {
        path: 'settings',
        component: SettingsList,
        name: 'SettingsList',
        meta: { public: false }
      },
      {
        path: 'settings/looker-studios',
        component: LookerStudiosList,
        name: 'LookerStudiosList',
        meta: { public: false }
      },
      {
        path: 'settings/review-products',
        component: ProductsReviewList,
        name: 'Review Products',
        meta: { public: false, breadcrumbDir: 'Settings' }
      },
      {
        path: 'settings/call-off-approvals',
        component: CallOffsApprovalList,
        name: 'Review Call Off Signed Docs',
        meta: { public: false, breadcrumbDir: 'Settings' }
      },
      {
        path: 'settings/bcp-approvals',
        component: BcpList,
        name: 'BCP Approvals',
        meta: { public: false, breadcrumbDir: 'Settings' }
      },
      {
        path: 'firebase_notifications',
        component: FirebaseNotificationComponent,
        name: 'Firebase Notifications',
        meta: { public: false }
      },
      {
        path: 'procurement_projects',
        component: ProcurementProjectsComponent,
        name: 'procurement_projects',
        meta: { public: false }
      },
      {
        path: 'my_pipeline',
        component: MyPipelineComponent,
        name: 'my_pipeline',
        meta: { public: false }
      },
      {
        path: 'planned_procurement_notices',
        component: PinNoticesList,
        name: 'planned_procurement_notices',
        meta: { public: false },
        children: [
          {
            path: 'contracting_authority/activate/:activation_code',
            component: PinNoticesList,
            name: 'activate_contracting_authority',
            meta: { public: false }
          },
        ]
      },
      {
        path: 'planned_procurement_notices/:id',
        name: 'View PPN',
        component: SupplierViewPinPage,
        props: true,
        meta: { public: false }
      },
      {
        path: 'all-card-imports/:category',
        component: AllCardImportsList,
        name: 'AllCardImportsList',
        meta: { public: false }
      },
      {
        path: 'all_bank_report_imports/:category',
        component: AllBrImportList,
        name: 'all_bank_report_imports',
        meta: { public: false }
      },
      {
        path: 'tender_notices',
        component: IttFrameworksList,
        name: 'IttFrameworksList',
        meta: { public: false }
      },
      {
        path: 'support-ticket',
        component: SupportTicketHome,
        name: 'SupportTicketHome',
        meta: { public: false }
      },
      {
        path: 'dashboard/monthly_reporting_category',
        component: MonthlyReportingCategory,
        name: 'monthly_reporting_category',
        meta: { public: false, breadcrumbDir: 'Dashboard' },
      },
      {
        path: 'dashboard/monthly_reporting/:category',
        component: MonthlyReporting,
        name: 'monthly_reporting',
        meta: { public: false, breadcrumbDir: 'Dashboard' }
      },
      {
        path: 'dashboard/monthly_reporting/:category/banking',
        component: MRBanking,
        name: 'monthly_reporting_banking',
        meta: {
          public: false,
          breadcrumbDir: 'Dashboard / Monthly Reporting',
          breadcrumb: 'Banking'
        }
      },
      {
        path: 'dashboard/monthly_reporting/:category/merchant_services',
        component: MRMerchantServices,
        name: 'monthly_reporting_merchant_services',
        meta: {
          public: false, 
          breadcrumbDir: 'Dashboard / Monthly Reporting',
          breadcrumb: 'Merchant Services'
        }
      },
      {
        path: 'supplier',
        component: SupplierSettingComponent,
        name: 'supplier',
        meta: { public: false, breadcrumb: 'Supplier Account' }
      },
      {
        path: 'company',
        component: CompanySettingsCompany,
        name: 'company',
        meta: { public: false, breadcrumb: 'Company Account' }
      },
      {
        path: 'files',
        component: FilesManagementComponent,
        name: 'files',
        meta: { public: false, breadcrumbDir: 'Files' }
      },
      {
        path: 'councils',
        component: CouncilsList,
        name: 'councils',
        meta: { public: false, breadcrumbDir: 'Councils' }
      },
      {
        path: 'framework_names',
        component: frameworkNameList,
        name: 'framework_names',
        meta: { public: false, breadcrumbDir: 'framework_names' }
      },
      {
        path: 'supplier_specifications',
        component: SupplierSpecificationsComponent,
        name: 'supplier_specifications',
        meta: { public: false }
      },
      {
        path: 'product_search_results',
        component: ProductSearchResultsComponent,
        name: 'product_search_results',
        meta: { public: false, breadcrumb: 'Buyer Pricing Requests' }
      },
      {
        path: 'meetings',
        component: MeetingsHomeComponent,
        name: 'meetings',
        meta: { public: false, breadcrumb: 'Meetings' }
      },
      {
        path: 'detailed-cost-comparison',
        component: DetailedCostComparisonFromProductSearchResults,
        name: 'DetailedCostComparisonFromProductSearchResults',
        meta: { public: false, breadcrumb: 'Detailed Cost Comparison' }
      },
      {
        path: 'product_search_queries',
        component: BuyerSearchQueriesMainComponent,
        name: 'product_search_queries',
        meta: { public: false },
        children: [
          {
            name: "ProductSearchQueriesHome",
            path: '',
            redirect: to => {
              return { name: 'product_search_queries' }
            },
          },
          {
            path: 'list',
            name: 'product_search_queries_list',
            component: BuyerSearchQueriesComponent,
            props: true,
            meta: { public: false }
          },
          {
            path: 'list/:id/view',
            name: 'BuyerSearchQuery',
            component: SelectedBuyerSearchQueryComponent,
            props: true,
            meta: { public: false }
          }
        ]
      },
      {
        path: 'call-offs',
        component: SupplierPendingCallOffsComponent,
        name: 'SupplierPendingCallOffsComponent',
        meta: { public: false }
      },
      {
        path: 'buyer-call-off-documents',
        component: BuyerCallOffDocuments,
        name: 'BuyerCallOffDocuments',
        meta: { public: false }
      },
      {
        path: 'product_search_messages',
        component: ProductSearchMessageList,
        name: 'ProductSearchMessageList',
        meta: { public: false }
      },
      {
        path: 'product_rfq',
        component: ProductRfqsComponent,
        name: 'product_rfq',
        meta: { public: false }
      },
      {
        path: 'products',
        component: ProductsMainComponent,
        name: 'products',
        meta: { public: false },
        redirect: to => {
          return { name: 'products_list' }
        },
        children: [
          {
            path: 'list',
            name: 'products_list',
            component: ProductsComponent,
            props: true,
            meta: { public: false, breadcrumb: 'Products' }
          },
          {
            path: 'list/:id/view',
            name: 'Product',
            component: SelectedProductComponent,
            props: true,
            meta: {
              public: false,
              breadcrumbDir: 'Products',
              breadcrumb: 'Edit Product'
            }
          }
        ]
      },
     
      {
        path: 'card_type_terms',
        component: CardTypeTermsComponent,
        name: 'card_type_terms',
        meta: { public: false, breadcrumbDir: 'Card Transactions' }
      },
      {
        path: 'companies',
        component: CompaniesComponent,
        name: 'companies',
        meta: { public: false }
      },
      {
        path: 'merchant-transactions-report',
        component: MerchantTransactionsReportListComponent,
        name: 'merchant_transactions_component',
        meta: { public: false, breadcrumb: 'Merchant Transactions Report' }
      },
      {
        path: 'categories',
        component: CategoriesComponent,
        name: 'categories',
        meta: { public: false }
      },
      {
        path: 'users',
        component: UsersComponent,
        name: 'users',
        meta: { public: false, breadcrumbDir: 'Settings' }
      },
      {
        path: 'specifications',
        component: SpecificationsComponent,
        name: 'specifications',
        meta: {
          public: false,
          breadcrumbDir: 'Settings',
          breadcrumb: 'Global Specifications'
        }
      },
      {
        path: 'company-faca-list',
        component: CompanyFacaList,
        name: 'CompanyFaca',
        meta: {
          public: false,
          breadcrumbDir: 'Settings',
          breadcrumb: 'Company Faca List'
        }
      },
      {
        path: 'social_values',
        component: SocialValuesComponent,
        name: 'social_values',
        meta: {
          public: false,
          breadcrumbDir: 'Settings',
          breadcrumb: 'Global Social Values'
        }
      },
      {
        path: 'supplier_social_values',
        component: SupplierSocialValuesComponent,
        name: 'supplier_social_values',
        meta: {
          public: false,
          breadcrumbDir: 'Procurement',
          breadcrumb: 'Supplier Social Values'
        }
      },
      {
        path: 'merchant_terminologies',
        name: 'merchant_terminologies',
        component: MerchantTerminologies,
        meta: { public: false }
      },
      {
        path: 'card_transactions/:category',
        component: CardTransactionsMainComponent,
        name: 'card_transactions',
        meta: {
          public: false,
          breadcrumbDir: 'Card Transactions',
          breadcrumb: 'Cost Data and Reports'
        }
      },
      {
        path: 'card-imports/:category',
        component: CardImportsList,
        name: 'CardImportsList',
        meta: { public: false, breadcrumbDir: 'Card Transactions' }
      },
      {
        path: 'invoices/:category',
        component: CtInvoiceList,
        name: 'invoices',
        meta: { public: false, breadcrumbDir: 'Card Transactions' }
      },
       {
        path: 'card_types/:category',
        component: CardTypeRatesComponent,
        name: 'card_types',
        meta: { public: false, breadcrumbDir: 'Card Transactions' }
      },
      {
        path: 'roles',
        component: RolesComponent,
        name: 'roles',
        meta: { public: false, breadcrumbDir: 'Settings' }
      },
      {
        path: 'authorization_fees',
        component: AuthorizationFeesComponent,
        name: 'authorization_fees',
        meta: { public: false, breadcrumbDir: 'Settings' }
      },
      {
        path: 'permissions',
        component: PermissionsComponent,
        name: 'permissions',
        meta: { public: false, breadcrumbDir: 'Settings' }
      },
      {
        path: 'global_transaction_types',
        component: GlobalTransactionTypesComponent,
        name: 'global_transaction_types',
        meta: { public: false }
      },
      {
        path: 'suppliers',
        component: SuppliersView,
        name: 'suppliers_home',
        redirect: to => {
          return { name: 'suppliers_list' }
        },
        children: [
          {
            path: 'list',
            name: 'suppliers_list',
            component: SuppliersComponent,
            props: true,
            meta: { public: false, breadcrumb: 'Suppliers' }
          },
          {
            path: ':supplier_id/transaction_types',
            name: 'transaction_types',
            component: TransactionTypesComponent,
            props: true,
            meta: { public: false }
          },
          {
            path: ':supplier_id/bff_rates',
            name: 'bff_rates',
            component: MerchantBffRatesComponent,
            props: true,
            meta: { public: false }
          }
        ]
      },
      {
        path: 'help_requests',
        component: HelpRequestComponent,
        name: 'help_requests_component',
        meta: { public: false, breadcrumb: 'Help Requests' }
      },
      {
        path: 'profile',
        component: Profile,
        name: 'profile'
      },
      {
        path: 'account-setting',
        component: AccountSetting,
        name: 'AccountSetting'
      },
      {
        path: 'user_account',
        name: 'user_account',
        component: PasswordReset
      },
      {
        path: 'application_histories',
        name: 'application_histories',
        component: HistoryLogsListcomponent
      },
      {
        path: 'project_list_settings',
        name: 'project_list_settings',
        component: ProjectSettingsViewComponent
      },
      {
        path: 'supplier_questionaire',
        name: 'supplier_questionaire',
        component: SQuestionComponent
      },
      {
        path: 'evaluation_result_submissions',
        name: 'evaluation_result_submissions',
        component: SubmissionList
      },
      {
        path: 'bank_reports',
        name: 'bank_reports',
        component: BankReports,
        meta: { public: false },
        children: [
          {
            path: 'transactions/:category',
            name: 'br_transactions',
            component: BrTransaction,
            meta: {
              public: false,
              breadcrumbDir: 'Bank Reports',
              breadcrumb: 'Transactions'
            },
            children: [
              {
                path: '',
                name: 'br_transaction_list',
                component: BrTransactionList,
                meta: {
                  public: false,
                  breadcrumbDir: 'Bank Reports',
                  breadcrumb: 'Transactions'
                }
              },
              {
                path: 'reports',
                name: 'br_transaction_reports',
                component: BrTransactionReportList,
                meta: {
                  public: false,
                  breadcrumbDir: 'Bank Reports',
                  breadcrumb: 'Reports'
                }
              },
              {
                path: 'exports',
                name: 'br_exports',
                component: BRExportsListComponent,
                meta: {
                  public: false,
                  breadcrumbDir: 'Bank Reports',
                  breadcrumb: 'Exports'
                }
              },
              {
                path: 'history',
                name: 'br_transaction_history',
                component: BrTransactionHistory,
                meta: {
                  public: false,
                  breadcrumbDir: 'Bank Reports',
                  breadcrumb: 'Historical Data'
                }
              }
            ]
          },
          {
            path: 'tariff_rates/:category',
            name: 'br_tariff_rates',
            component: BrTariffRateList,
            meta: {
              public: false,
              breadcrumbDir: 'Bank Reports',
              breadcrumb: 'Tariff Rates'
            }
          },
          {
            path: 'freebankings',
            name: 'br_freebankings',
            component: BrFreebankingList,
            meta: {
              public: false,
              breadcrumbDir: 'Bank Reports',
              breadcrumb: 'Freebankings'
            }
          },
          {
            path: 'imports/:category',
            name: 'br_imports',
            component: BrImportList,
            meta: {
              public: false,
              breadcrumbDir: 'Bank Reports',
              breadcrumb: 'Imports'
            }
          },
          {
            path: 'invoices/:category',
            name: 'br_invoices',
            component: BrInvoiceList,
            meta: {
              public: false,
              breadcrumbDir: 'Bank Reports',
              breadcrumb: 'Invoices'
            }
          }
        ]
      },
      {
        path: 'banking/supplier_prospects',
        name: 'banking_supplier_prospects',
        component: BankingSupplierProspectList,
        meta: { public: false, breadcrumb: 'Banking' }
      },
      {
        path: 'banking/supplier_prospects/:supplier_prospect_id/tariff_rates',
        name: 'banking_tariff_rates',
        component: BankingTariffRateList,
        meta: { public: false, breadcrumb: 'Banking' }
      },
      {
        path: 'banking/cost_comparison',
        name: 'banking_cost_comparison',
        component: BankingCostComparison,
        meta: { public: false, breadcrumb: 'Banking' }
      },
      {
        path: 'banking/cost_comparison/:id',
        name: 'banking_cost_comparison_edit',
        component: BankingCostComparison,
        meta: { public: false, breadcrumb: 'Banking' }
      },
      {
        path: 'banking/reports',
        name: 'banking_reports',
        component: BankingReportList,
        meta: { public: false, breadcrumb: 'Banking' }
      },
      {
        path: 'finance_module/calendar',
        name: 'finance_module_calendar',
        component: FinanceCalendar,
        meta: {
          public: false,
          breadcrumbDir: 'Finance Module',
          breadcrumb: 'Calendar'
        }
      }
    ]
  },

  {
    path: '/finance',
    name: 'FinanceMain',
    meta: { public: false },
    component: FinanceMain,
    redirect: to => {
      return { name: 'ChartOfAccounts' }
    },
    children: [
      {
        path: 'aging-report',
        name: 'AgingReport',
        component: AgingReport,
        meta: { public: false }
      },
      {
        path: 'settings/chart-of-accounts',
        name: 'ChartOfAccounts',
        component: ChartOfAccounts,
        meta: { public: false }
      },
      {
        path: 'settings/types',
        name: 'FinanceTypesList',
        component: FinanceTypesList,
        meta: { public: false }
      },
      {
        path: 'settings/areas',
        name: 'FinanceAreasList',
        component: FinanceAreasList,
        meta: { public: false }
      }
    ]
  },
  {
    path: '/supplier-hub',
    name: 'SupplierHubMain',
    meta: { public: false },
    component: SupplierHubMain,
    redirect: to => {
      return { name: 'ManageSupplierHubFiles' }
    },
    children: [
      {
        path: 'manage',
        name: 'ManageSupplierHubFiles',
        component: ManageSupplierHubFiles,
        meta: { public: false }
      },
      {
        path: 'notifications',
        name: 'SupplierHubNotifications',
        component: SupplierHubNotifications,
        meta: { public: false }
      }
    ]
  },
  {
    path: '/questions-and-answers',
    name: 'QuestionsAndAnswersMain',
    meta: { public: false },
    component: QuestionsAndAnswersMain,
    redirect: to => {
      return { name: 'ManageSupplierHubQuestions' }
    },
    children: [
      {
        path: 'manage',
        name: 'ManageSupplierHubQuestions',
        component: ManageSupplierHubQuestions,
        meta: { public: false }
      }
    ]
  },
  {
    path: '/company/invoices',
    name: 'company_invoices',
    component: CompanyInvoiceList,
    props: { company: true },
    meta: { public: false }
  },
  {
    path: '/cost_comparison_transaction/:group_code',
    name: 'Cost Comparison Transaction Report',
    component: BankTransactionReport,
    props: true,
    meta: { public: true }
  },
  {
    path: '/merchant-services',
    name: 'Merchant Services',
    component: MerchantService,
    meta: { public: false },
    children: [
      {
        path: '/merchant-services/:code',
        name: 'One Merchant Service',
        component: MerchantService,
        props: true,
        meta: { public: false }
      }
    ]
  },
  {
    path: '/merchant-services/:code/report',
    name: 'Merchant Service Report',
    component: MerchantServiceReport,
    props: true,
    meta: { public: false }
  },
  {
    path: '/:catchAll(.*)',
    name: 'Page Not Found',
    component: PageNotFound,
    meta: { public: true }
  },
  {
    path: '/page_unavailable',
    name: 'Page Unavailable',
    component: RestrictAfterLoggedInComponent,
    meta: { public: true }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  store.commit('saveChangingRoute', true)
  next()
})
router.afterEach(() => {
  store.commit('saveChangingRoute', false)
})

export default router
